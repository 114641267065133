import { Injectable, EventEmitter } from '@angular/core';
import { TradingInstrument } from '../../../../shared-components/trading-instruments/trading-instrument.class';
import {
   TradingInstrumentsService, UniqueUnderlying
} from '../../../../shared-components/trading-instruments/trading-instruments-service.interface';
import { TradingInstrumentKind } from 'projects/shared-components/trading-instruments/trading-instrument-kind.enum';
import * as LZString from 'lz-string';
import { HttpClient } from '@angular/common/http';
import { isTruthy } from 'projects/shared-components/utils';

@Injectable({ providedIn: 'root' })
export class WebtraderTradingInstrumentsService extends TradingInstrumentsService {

   constructor(private _http: HttpClient) {
      super();
      this._tradingInstruments = [];
      this._tradingInstrumentsByTicker = {};
      this._tradingInstrumentsByDisplayName = {};

      this.newInstrument = new EventEmitter<TradingInstrument>();
   }

   newInstrument: EventEmitter<TradingInstrument>;

   private _tradingInstruments: TradingInstrument[];
   private _tradingInstrumentsByTicker: Record<string, TradingInstrument>;
   private _tradingInstrumentsByDisplayName: Record<string, TradingInstrument>;
   private _uniqueUnderlyings: UniqueUnderlying[];

   init(): Promise<void> {

      return this._http.get('/assets/_ie.txt', {responseType: 'text'}).toPromise()
         .then( data => {

            console.group("ti parsing");

            const start = Date.now();

            const decompressed = LZString.decompressFromUTF16(data);

            const dcmprsd = Date.now();

            console.log('decompressed', dcmprsd - start);

            const instruments: string[] = JSON.parse(decompressed);

            this.makeInstruments(instruments);

            const instr = Date.now();
            console.log('instruments', instr-dcmprsd);

            console.log('total', instr - start);

            console.groupEnd();


            console.debug('Initialized');

         });
   }

   getAllTradingInstruments(): TradingInstrument[] {
      return this._tradingInstruments.slice();
   }

   getInstrumentByTicker(ticker: string): TradingInstrument {
      return this._tradingInstrumentsByTicker[ticker];
   }

   getInstrumentByDisplayName(name: string): TradingInstrument {
      if (!name) {
         return null;
      }
      return this._tradingInstrumentsByDisplayName[name.toLowerCase()];
   }

   register(ti: TradingInstrument) {
      this._tradingInstruments.push(ti);
      this._tradingInstrumentsByTicker[ti.ticker] = ti;
      this.newInstrument.emit(ti);
   }

   getUniqueUnderlyings(): UniqueUnderlying[] {
      return this._uniqueUnderlyings;
   }

   private makeInstruments(instruments: string[]) {

      this._tradingInstruments.length = 0;
      this._tradingInstrumentsByTicker = {};

      const tradingInstruments = instruments.map(this.parseTradingInstrument).sort( (a, b) => {
         if (a.expirationDate && !b.expirationDate) {
            return 1;
         }

         if (!a.expirationDate && b.expirationDate) {
            return -1;
         }

         if (!a.expirationDate && !b.expirationDate) {
            return a.ticker.localeCompare(b.ticker);
         }

         const diff = a.expirationDate.getTime() - b.expirationDate.getTime();

         return Math.sign(diff);

      } );

      this._tradingInstruments.push(...tradingInstruments);

      this._tradingInstruments.forEach((instr: TradingInstrument) => {

         this._tradingInstrumentsByTicker[instr.ticker] = instr;
         this._tradingInstrumentsByDisplayName[(instr.displayName + '').toLowerCase()] = instr;

      });

      const uls = this._tradingInstruments.flatMap(instr => {
         
         if (instr.kind === TradingInstrumentKind.Stock) {
            return [
               { displayName: instr.underlying, underlying: instr.underlying, kind: TradingInstrumentKind[instr.kind] },
               { displayName: instr.underlying, underlying: instr.underlying, kind: 'Option' }
            ]; 
         } else if (instr.kind === TradingInstrumentKind.Index) {
            return [{ displayName: instr.underlying, underlying: instr.underlying, kind: 'Option' }];
         }

         return [{ displayName: instr.underlying, underlying: instr.underlying, kind: TradingInstrumentKind[instr.kind] }];
   
      }).filter( (v , ix, arr) => arr[ix] === v);

      this._uniqueUnderlyings = uls;      
   }

   private parseTradingInstrument(code: string): TradingInstrument {
      if (!isTruthy(code)) {
         return null;
      }

      const parts = code.split(',');

      const kind = TradingInstrumentKind[parts[0]];
      const ticker = parts[1];
      const underlying = parts[2];
      const exchange = parts[3];
      const description = parts[4];
      const tickSize = parseFloat(parts[5]);
      const pointValue = parseFloat(parts[6]);
      const precision = parseInt(parts[7]);
      const expirationDate = isTruthy(parts[8]) ? new Date(parts[8]) : null;
      const exchangeTimeZoneId = parts[9];
      const displayName = parts[10];

      const ti: TradingInstrument = {
         kind,
         ticker,
         underlying,
         exchange,
         description,
         tickSize,
         pointValue,
         precision,
         expirationDate,
         exchangeTimeZoneId,
         displayName
      };

      return ti;

   }
}
