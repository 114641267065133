import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { WebtraderAppModule } from './app/wt-app.module';
import { LicenseManager } from 'ag-grid-enterprise';
import { environment } from 'projects/shared-components/environments/environment';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import Guid from 'devextreme/core/guid';

let backend = 'backend.evergreentradingsystems.net';
if (document.location.hostname.indexOf('backup') >=0 ) {
    backend = 'backend.tradingbackup.net';
} else if (document.location.hostname.indexOf('localhost') >=0 ) {
    backend = 'localhost';
} else if (document.location.hostname.indexOf('staging') >= 0) {
    backend = 'tradingstagingserver.net';
}

environment.eventStreamUrl = environment.eventStreamUrl.replace('{{host}}', backend);
environment.authServiceUrl = environment.authServiceUrl.replace('{{host}}', backend);
environment.bcServiceUrl = environment.bcServiceUrl.replace('{{host}}', backend);
environment.ccServiceUrl = environment.ccServiceUrl.replace('{{host}}', backend);
environment.shellRouterUrl = environment.shellRouterUrl.replace('{{host}}', backend);
environment.telemetryServiceUrl = environment.telemetryServiceUrl.replace('{{host}}', backend);

environment.runtimeAppId = EtsConstants.companyServices.etsWebTraderApplicationId;
environment.runtimeInstanceId = new Guid().valueOf();
LicenseManager.setLicenseKey(environment.agGridLicenseKey);

if (environment.production) {
   enableProdMode();
}

const favIcon: HTMLLinkElement = document.querySelector('#appIcon');

if (favIcon) {

   let href = '';

   if (document.location.hostname.indexOf('mastery') >= 0) {
      href = 'https://tradingmasteryhq.com/wp-content/uploads/2018/05/favicon.png';
   } else if (document.location.hostname.indexOf('evergreen') >= 0) {
      href = 'assets/favicon-grey.ico';
   } else {
      href = 'assets/favicon-grey.ico';
   }

   favIcon.href = href;

}


platformBrowserDynamic().bootstrapModule(WebtraderAppModule)
   .then(ref => {

      // Ensure Angular destroys itself on hot reloads.
      if (window['ngRef']) {
         window['ngRef'].destroy();
      }
      window['ngRef'] = ref;

      // Otherwise, log the boot error
   })
   .catch(err => console.error(err));
