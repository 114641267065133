import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsStorageService } from './settings-storage-service.service';
import { MessageBusService } from './message-bus.service';
import { ShellClientService } from './shell-communication/shell-client.service';
import { GetOptionChainShellResponse } from './shell-communication/shell-dto-protocol';
import { GetOptionChainShell } from './shell-communication/shell-operations-protocol';
import { PrefetchOptionChainUIMessage } from './ui-messages/ui-messages';
import { daysToExpiration, isVoid } from './utils';


@Injectable({ providedIn: 'root' })
export class OptionsChainService {

   constructor(
      private _messageBusSevice: MessageBusService,
      private _layoutService: SettingsStorageService,
      private _shellClient: ShellClientService,
      private _messageBus: MessageBusService,
   ) {

   }

   private _unsubscriber = new Subject<void>();
   private _chainsIndex: Record<string, GetOptionChainShellResponse> = {};
   private _promisesIndex: Record<string, Promise<GetOptionChainShellResponse>> = {};

   //
   init(): Promise<void> {

      const start = Date.now();

      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }

      this._unsubscriber = new Subject<void>();

      this._chainsIndex = {};

      this._promisesIndex = {};

      this._messageBusSevice.of<PrefetchOptionChainUIMessage>('PrefetchOptionChainUIMessage')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.prefetchOptionChain(x.payload));

      // const favs: string[] = this._layoutService.getItem<string[]>('favorites') || [];
      //
      // // always prefetch SPY
      // if (favs.indexOf('SPY') < 0) {
      //    favs.push('SPY');
      // }

      // favs.forEach(ticker => this.prefetchOptionChain({ ticker }));

      const end = Date.now();

      this._messageBus.publish({
         topic: 'ServiceInitialized',
         payload: {
            time: end-start,
            name: 'OptionChains'
         }
      });

      return Promise.resolve(null);
   }

   //
   getChain(ticker: string): Promise<GetOptionChainShellResponse> {

      if (isVoid(ticker)) {
         return Promise.resolve(null);
      }

      const existing = this._chainsIndex[ticker];

      if (existing && existing.expirations.length > 0) {
         return Promise.resolve(existing);
      }

      delete this._chainsIndex[ticker];

      const promise = this._promisesIndex[ticker];

      if (promise) {
         return promise;
      }

      return this.prefetchOptionChain({ ticker });
   }

   //
   prefetchOptionChain(msg: PrefetchOptionChainUIMessage): Promise<GetOptionChainShellResponse> {

      const ticker = msg.ticker;

      const qry = new GetOptionChainShell(ticker);

      const prms = this._shellClient.processQuery<GetOptionChainShellResponse>(qry);

      this._promisesIndex[ticker] = prms;

      prms.then(x => {

         x.expirations.forEach(exp => {

            const diffIndays = daysToExpiration(exp.optionExpirationDate);

            const displayDate = `${exp.optionExpirationDisplayDate} (${diffIndays}d)`;

            exp.daysToExpiration = diffIndays;

            exp.dateWithDaysToExpiration = displayDate;

            if (exp.underlyingSymbol === 'SPX') {
               exp.strikeStep = 5;
            } else if (exp.underlyingSymbol === 'TSLA') {
               exp.strikeStep = 2.5;
            } else {
               exp.strikeStep = 1;
            }
  
         });

         if (x.expirations.length > 0) {
            this._chainsIndex[ticker] = x;
         }

         delete this._promisesIndex[ticker];

      })
         .catch(err => {

            console.error(err);
            delete this._promisesIndex[ticker];

         });

      return prms;
   }
}
