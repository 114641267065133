import {Injectable} from '@angular/core';
import {getShortUUID, isNullOrUndefined, isVoid, removeFromArray} from "../utils";
import * as Enumerable from "linq";
import {SessionService} from "../authentication/session-service.service";
import {MessageBusService} from "../message-bus.service";
import {PricingGridStrategyDescriptor} from "./model/pricing-grid-strategy.descriptor";
import {UserSettingsService} from "../user-settings.service";
import {ShellClientService} from "../shell-communication/shell-client.service";
import {
    DeleteAssignableOpgTemplateShell,
    GetAvailableOpgTemplates,
    GetAvailableOpgTemplatesReply,
    SaveAssignableOpgTemplateShell
} from "../shell-communication/shell-operations-protocol";

export interface OpgTemplate {
    templateId: string;
    templateName: string;
    underlying: string;
    descriptors: PricingGridStrategyDescriptor[];
    isAssignable?: boolean;
}

const CallTemplatesStorageKey: string = 'opg.templates.call';
const PutTemplatesStorageKey: string = 'opg.templates.put';

@Injectable({providedIn: 'root'})
export class OptionPricingGridTemplatesService {

    constructor(
        private readonly _userSettingsService: UserSettingsService,
        private readonly _sessionService: SessionService,
        private readonly _messageBusService: MessageBusService,
        private readonly _shellClient: ShellClientService
    ) {
    }

    private _callTemplates: {[ix: string]: OpgTemplate[] } = {};
    private _putTemplates: { [ix: string]: OpgTemplate[] } = {};
    private _groupedCallTemplates: any = {};
    private _groupedPutTemplates: any = {};
    private _assignedCallTemplates : OpgTemplate[] = [];
    private _assignedPutTemplates : OpgTemplate[] = [];

    async init() : Promise<void> {

        const start = Date.now();

        this._callTemplates = {};
        this._putTemplates = {};

        const assignableCallTemplatesPrms = this.loadAssignableTemplates('Call');
        const assignablePutTemplatesPrms = this.loadAssignableTemplates('Put');

        const calls = await assignableCallTemplatesPrms;
        const parsedCalls = calls.map(x => JSON.parse(x)) as OpgTemplate[];
        this._assignedCallTemplates = parsedCalls;

        const puts = await assignablePutTemplatesPrms;
        const parsedPuts = puts.map(x => JSON.parse(x)) as OpgTemplate[];
        this._assignedPutTemplates = parsedPuts;

        const end = Date.now();

        this._messageBusService.publish({
            topic: 'ServiceInitialized',
            payload: {
                time: end-start,
                name: 'OPG Templates'
            }
        });
    }

    getGroupedCallTemplates(userId?: string, refresh?: boolean) : {key: string, items: OpgTemplate[]}[] {
        const result = this.getGroupedTemplates('Call', userId, refresh);
        return result;
    }

    getGroupedPutTemplates(userId?: string, refresh?: boolean) : {key: string, items: OpgTemplate[]}[] {
        const result = this.getGroupedTemplates('Put', userId, refresh);
        return result;
    }

    private getGroupedTemplates(side: 'Call' | 'Put', userId?: string, refresh?: boolean) : {key: string, items: OpgTemplate[]}[] {

        const containerProvider = side === 'Call' ? this._groupedCallTemplates : this._groupedPutTemplates;

        let container = containerProvider[userId];

        if (isVoid(container) || refresh) {
            const templates =  side === 'Call'
                ? this.getCallTemplates(userId, refresh)
                : this.getPutTemplates(userId, refresh);

            const grouped = Enumerable.from(templates)
                .groupBy(x => (x.isAssignable || false))
                .select(grp => {
                    const data = {
                        key: grp.key() ? 'Assigned Templates' : 'Own Templates',
                        items: grp.toArray()
                    };
                    return data;
                }).orderBy(x => x.key).toArray();

            container = grouped;

            containerProvider[userId] = container;
        }

        return container;
    }

    getCallTemplates(userId?: string, refresh?: boolean): OpgTemplate[] {

        const result = this.getTemplates('Call', userId, refresh);
        return result;
    }

    getPutTemplates(userId?: string, refresh?: boolean): OpgTemplate[] {
        const result = this.getTemplates('Put', userId, refresh);
        return result;
    }

    private getTemplates(side: 'Call' | 'Put', userId?: string, refresh?: boolean) : OpgTemplate[] {
        refresh = false

        if (isVoid(userId)) {
            userId = this._sessionService.sessionData.userId;
        }

        const containerProvider = side === 'Call' ? this._callTemplates : this._putTemplates;

        let container = containerProvider[userId];

        const storageKey = side === 'Call' ? CallTemplatesStorageKey : PutTemplatesStorageKey;


        if (isVoid(container) || refresh) {

            container = this._userSettingsService.getValue<OpgTemplate[]>(storageKey, userId) || [];

            container = container.filter(x => !x.isAssignable);

            let hadEmptyId = false;

            container.forEach(x => {
                x.descriptors.forEach(d => {
                    if (isVoid(d.strategyId)) {
                        hadEmptyId = true;
                        d.strategyId = getShortUUID();
                    }
                });
            });

            const assignedTemplates = side === 'Call' ? this._assignedCallTemplates : this._assignedPutTemplates;

            container.push(...assignedTemplates);

            const sortedContainer = Enumerable
                .from(container)
                .orderBy(x => x.templateName.toLowerCase())
                .toArray();

            containerProvider[userId] = sortedContainer;

            if (hadEmptyId) {
                this._userSettingsService.setValue(storageKey, container.slice(), userId);
            }
        }

        return container;
    }

    save(tpl: OpgTemplate, targetSide: 'calls' | 'puts', userId?: string) {

        if (isVoid(tpl)) {
            return;
        }


        if (targetSide !== 'calls' && targetSide !== 'puts') {
            return;
        }

        tpl.descriptors.forEach(x => {
            x.templateId = tpl.templateId;
            x.templateName = tpl.templateName;
        });

        const sideContainer = targetSide === 'calls' ? this._callTemplates : this._putTemplates;

        if (isNullOrUndefined(sideContainer)) {
            return;
        }

        if (isVoid(userId)) {
            userId = this._sessionService.sessionData.userId;
        }

        const container = sideContainer[userId];

        if (isNullOrUndefined(container)) {
            return;
        }

        if (container.findIndex(x => x.templateId === tpl.templateId) === -1) {
            container.push(tpl);
        }

        if (tpl.isAssignable) {
            this.saveAssignableTemplate(tpl, targetSide).then(() => {});
        } else {
            const storageKey = targetSide === 'calls' ? CallTemplatesStorageKey : PutTemplatesStorageKey;
            const ownTemplates = container.filter(x => !x.isAssignable);
            this._userSettingsService.setValue(storageKey, ownTemplates, userId);
        }

        const sortedContainer = Enumerable
            .from(container)
            .orderBy(x => x.templateName.toLowerCase())
            .toArray();

        sideContainer[userId] = sortedContainer;

        this._groupedCallTemplates = {};
        this._groupedPutTemplates = {};

        this._messageBusService.publish({
            topic: 'Opg.TemplatesChanged',
            payload: {
                template: tpl,
                side: targetSide,
                userId: userId
            }
        });
    }

    remove(tpl: OpgTemplate, targetSide: 'calls' | 'puts', userId?: string) {

        if (targetSide !== 'calls' && targetSide !== 'puts') {
            return;
        }

        const sideContainer = targetSide === 'calls'
            ? this._callTemplates
            : this._putTemplates;

        if (isNullOrUndefined(sideContainer)) {
            return;
        }

        if (isVoid(userId)) {
            userId = this._sessionService.sessionData.userId;
        }

        const container = sideContainer[userId];

        if (isNullOrUndefined(container)) {
            return;
        }

        removeFromArray(container, tpl);

        if (!tpl.isAssignable) {

            const storageKey= targetSide === 'calls'
                ? CallTemplatesStorageKey
                : PutTemplatesStorageKey;

            const ownTemplates = container.filter(x => !x.isAssignable);

            this._userSettingsService.setValue(storageKey, ownTemplates, userId);

        } else {
            const cmd = new DeleteAssignableOpgTemplateShell(tpl.templateId);
            this._shellClient.processCommand(cmd).then(() => {})
                .catch(() => {
                    console.error(`Error deleting assignable template [${tpl.templateName}]. Id = ${tpl.templateId}`);
                });
        }

        this._groupedPutTemplates = {};
        this._groupedCallTemplates = {};

        this._messageBusService.publish({
            topic: 'Opg.TemplatesChanged',
            payload: {
                template: tpl,
                side: targetSide,
                userId: userId
            }
        });
    }

    private async loadAssignableTemplates(side: 'Call' | 'Put') {
        const query = new GetAvailableOpgTemplates(side);
        const reply = await this._shellClient
            .processQuery<GetAvailableOpgTemplatesReply>(query);
        return reply.templates;
    }

    private async saveAssignableTemplate(tpl: OpgTemplate, targetSide: "calls" | "puts") {

        const side = targetSide === 'calls' ? 'Call' : 'Put';
        const template = JSON.stringify(tpl);
        const templateId = tpl.templateId;

        const cmd = new SaveAssignableOpgTemplateShell(
            templateId,
            template,
            side
        );

        this._shellClient.processCommand(cmd).then((result) => {});
    }
}