import {Injectable, EventEmitter} from '@angular/core';
import {SessionService} from '../authentication/session-service.service';
import {ShellClientService} from '../shell-communication/shell-client.service';
import {MessageBusService} from '../message-bus.service';
import {
    GetUnreadShellMessages,
    AckShellMessages,
    ReadShellMessages
} from '../shell-communication/shell-operations-protocol';
import {
    ShellConnectionStatusChangedUIMessage
} from '../ui-messages/shell-connection-status-changed-ui-message.interface';
import {takeUntil} from 'rxjs/operators';
import {AvailableShellDto} from '../authentication/dtos/available-shell-dto.interface';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {ShellMessageDto} from '../shell-communication/shell-dto-protocol';


@Injectable({providedIn: 'root'})
export class ShellMessagesService {

    constructor(
        private readonly _sessionService: SessionService,
        private readonly _shellService: ShellClientService,
        private readonly _messageBus: MessageBusService,
        private readonly _toastr: ToastrService,
    ) {
    }

    //

    private _messagesIx: ShellMessageDto[] = [];
    private _unsubscriber: Subject<any> = new Subject<any>();

    //

    readonly shellMessageReceived$ = new EventEmitter<ShellMessageDto>();
    readonly closeOverlay$ = new EventEmitter<void>();
    readonly shellMessagesAcked$ = new EventEmitter<ShellMessageDto[]>();
    readonly shellMessagesRead$ = new EventEmitter<ShellMessageDto[]>();

    //

    async init(): Promise<void> {

        if (this._unsubscriber) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
        }

        this._unsubscriber = new Subject<any>();

        this._messagesIx = [];

        this._messageBus
            .of<ShellMessageDto>('ShellMessageDto')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe((msg) => this.onShellMessage(msg.payload));

        const dtos = await this.loadMessages();
        dtos.sort((x, y) => (x.level === y.level) ? 0 : x.level === 'Critical' ? -1 : 1);

        this._messagesIx.push(...dtos);
    }

    //

    clear(shellId?: string) {
        if (!shellId) {
            this._messagesIx.length = 0;
        } else {
            this._messagesIx.filter(x => x.shellId === shellId)
                .forEach(dto => {
                    const ix = this._messagesIx.indexOf(dto);
                    if (ix > -1) {
                        this._messagesIx.splice(ix, 1);
                    }
                });
        }
    }

    //

    private loadMessages(): Promise<ShellMessageDto[]> {
        const shells: AvailableShellDto[] = this._sessionService.getAffectedShells(null);

        if (shells.length === 0) {
            return Promise.resolve([]);
        }

        const messages: ShellMessageDto[] = [];

        const promises = shells.map(shell => {
            const promise = new Promise((resolve, reject) => {
                this._shellService
                    .processQuery<ShellMessageDto[]>(new GetUnreadShellMessages(), shell.shellId)
                    .then(data => {
                        messages.push(...data);
                        resolve(null);
                    })
                    .catch(err => {
                        resolve(null);
                    });
            });

            return promise;
        });

        const result = Promise.all(promises).then(d => messages).catch(err => messages);

        return result;
    }

    //

    getAllMessages(): ShellMessageDto[] {
        return this._messagesIx.sort(
            (x, y) => (x.level === y.level) ? 0 : x.level === 'Critical' ? -1 : 1
        );
    }

    //

    addMessage(msg: ShellMessageDto) {
        this.onShellMessage(msg);
    }

    //

    async ackMessages(rows: ShellMessageDto[]): Promise<void> {

        const idsByShell = {};

        rows.forEach(row => {
            let container: number[] = idsByShell[row.shellId];
            if (!container) {
                container = [];
                idsByShell[row.shellId] = container;
            }
            container.push(row.shellMessageId);
        });


        const promises = Object.keys(idsByShell).map(shellId => {
            const ids = idsByShell[shellId];
            const cmd = new AckShellMessages(ids);

            return this._shellService.processCommand(cmd, shellId)
                .then(() => {

                    ids.forEach(id => {
                        const ix = this._messagesIx.findIndex(msg => msg.shellMessageId === id);
                        if (ix >= 0) {
                            this._messagesIx.splice(ix, 1);
                        }
                    });

                })
                .catch(() => {
                    this._toastr.error('"Ack Messages" operation completed with errors');
                });
        });

        await Promise.all(promises);

        this.shellMessagesAcked$.emit(rows);
    }

    //

    async readMessages(rows: ShellMessageDto[]): Promise<void> {

        const idsByShell = {};

        rows.forEach(row => {
            let container: number[] = idsByShell[row.shellId];
            if (!container) {
                container = [];
                idsByShell[row.shellId] = container;
            }
            container.push(row.shellMessageId);
        });


        const promises = Object.keys(idsByShell).map(shellId => {
            const ids = idsByShell[shellId];
            const cmd = new ReadShellMessages(ids);

            return this._shellService.processCommand(cmd, shellId)
                .then(() => {

                    ids.forEach(id => {
                        const ix = this._messagesIx.findIndex(msg => msg.shellMessageId === id);
                        if (ix >= 0) {
                            this._messagesIx.splice(ix, 1);
                        }
                    });

                })
                .catch(() => {
                    this._toastr.error('"Read Messages" operation completed with errors');
                });
        });

        await Promise.all(promises);

        rows.forEach(x => x.isRead = true);

        this.shellMessagesRead$.emit(rows);
    }

    //

    addFakeMessage(type, noOverlay) {
        if (noOverlay) {
            this.closeOverlay$.emit();
        }

        setTimeout(() => {
            if (type === 'sticky') {
                this.onShellMessage({
                    shellMessageId: Math.random() * 100,
                    timestamp: new Date(),
                    domain: 'Test',
                    source: 'Message Faker',
                    message: 'This is test sticky message',
                    level: 'Critical',
                    shellName: 'Test Shell',
                    clientName: 'Alan',
                    sticky: true
                });
            } else if (type === 'important') {
                this.onShellMessage({
                    shellMessageId: Math.random() * 100,
                    timestamp: new Date(),
                    domain: 'Test',
                    source: 'VIP Message Faker',
                    message: 'I am important message',
                    level: 'Critical',
                    shellName: 'Test Shell',
                    clientName: 'Alan'
                });
            } else if (type === 'regular') {
                this.onShellMessage({
                    shellMessageId: Math.random() * 100,
                    timestamp: new Date(),
                    domain: 'Test',
                    source: 'Message Faker',
                    message: 'I am not important message',
                    level: 'Info',
                    shellName: 'Test Shell',
                    clientName: 'Alan'
                });
            }
        }, noOverlay ? 1000 : 0);

    }

    //

    private async loadShellMessages(shellId) {
        const qry = new GetUnreadShellMessages();
        const dtos = await this._shellService.processQuery<ShellMessageDto[]>(qry, shellId);

        if (dtos.length > 0) {
            this._messagesIx.push(...dtos);
        }
    }

    //

    private async onShellConnectionStatusChanged(msg: ShellConnectionStatusChangedUIMessage): Promise<void> {
        await this.loadShellMessages(msg.shellId);
    }

    //

    private onShellMessage(dto: ShellMessageDto): void {
        if (dto.eventId) {
            const ix = this._messagesIx.findIndex((x) => x.eventId === dto.eventId);

            if (ix >= 0) {
                return;
            }
        }

        this._messagesIx.push(dto);

        this.shellMessageReceived$.emit(dto);

        this.showMessage(dto);

    }

    private showMessage(msg: ShellMessageDto) {
        if (msg.sticky) {
            return;
        }

        const toastTitle = `${msg.shellName} @ ${msg.clientName}`;

        if (msg.level === 'Critical') {

            this._toastr.error(msg.message, toastTitle, {positionClass: 'toast-bottom-right'});

        } else {

            const partialConfig = {positionClass: 'toast-bottom-right'};

            if (msg.level === 'Success') {
                this._toastr.success(msg.message, toastTitle, partialConfig);
            } else if (msg.level === 'Warning') {
                this._toastr.warning(msg.message, toastTitle, partialConfig);
            } else {
                this._toastr.info(msg.message, toastTitle, partialConfig);
            }

        }
    }
}
