import {Component, OnInit, ViewChild} from '@angular/core';
import { AuthResultDto } from '../../../../shared-components/authentication/dtos/auth-result-dto.inteface';
import { SessionService } from '../../../../shared-components/authentication/session-service.service';
import { Router } from '@angular/router';
import { ShellClientService } from '../../../../shared-components/shell-communication/shell-client.service';
import { AuthServiceClientService } from '../../../../shared-components/authentication/auth-service-client.service';
import { DismissToken } from '../../../../shared-components/authentication/operations/dismiss-token.class';
import { EtsConstants } from '../../../../shared-components/ets-constants.const';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { TerminalLoginResultDto } from '../../../../shared-components/shell-communication/dtos/terminal-login-result-dto.class';
import { KeepSessionAliveService } from '../../../../shared-components/authentication/keep-session-alive.service';
import { TerminalLogin } from '../../../../shared-components/shell-communication/operations/login/terminal-login.class';
import { AvailableShellDto } from '../../../../shared-components/authentication/dtos/available-shell-dto.interface';
import { Authenticate } from '../../../../shared-components/authentication/operations/authenticate.class';
import { environment } from 'projects/shared-components/environments/environment';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';
import { SettingsStorageService } from 'projects/shared-components/settings-storage-service.service';
import { LocationService } from 'projects/shared-components/location.service';
import {
   GenericConfirmationDialogComponent
} from "../../../../shared-components/generic-confirmation-dialog/generic-confirmation-dialog.component";

interface Credentials {
   username?: string;
   password?: string;
}

@Component({
   selector: 'ets-login',
   templateUrl: 'login.component.html',
   styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
   constructor(
      private readonly sessionService: SessionService,
      private readonly router: Router,
      private readonly toastr: ToastrService,
      private readonly shellClient: ShellClientService,
      private readonly authClient: AuthServiceClientService,
      private readonly keepSessionAliveService: KeepSessionAliveService,
      private readonly _layoutService: SettingsStorageService,
      private readonly _locationService: LocationService,
      loggerService: LoggerService
   ) {
      this.logger = loggerService.createLogger('LoginComponent');
   }

   private logger: Logger;

   get layoutService(): SettingsStorageService { return this._layoutService; }
   credentials: Credentials = {};
   isLoading: boolean;
   loginButtonText: string;
   selectedShell: AvailableShellDto;
   availableShells: AvailableShellDto[];
   isAuthenticated: boolean;
   productVersion: string;

   @ViewChild(GenericConfirmationDialogComponent)
   confirmDialog: GenericConfirmationDialogComponent;

   get isEtsHost(): boolean {
      return this._locationService.isEtsHost;
   }

   get formHeader(): string {
      let header = 'Welcome';
      if (this.isEtsHost) {
         header = 'ETS Webtrader';  
      }
      return header;
   }

   async ngOnInit(): Promise<void> {
      this.productVersion = environment.productVersion;

      if (this.sessionService.isAuthenticated) {
         this.logger.info('Session already authenticated. Redirecting to /app');
         await this.router.navigate(['/app']);
         return;
      }

      this.updateLoginButtonText();

      this.logger.debug('Initialized');
   }

   async onLoginButtonClicked(): Promise<void> {

      this.logger.info('User clicked "Login"', { isAuthenticated: this.isAuthenticated });

      if (!this.isAuthenticated) {
         const qry: Authenticate = new Authenticate(
            this.credentials.username,
            this.credentials.password,
            EtsConstants.companyServices.etsWebTraderApplicationId,
            environment.productVersion
         );

         this.isLoading = true;
         this.updateLoginButtonText();

         try {
            const data = await this.authClient.processQuery<AuthResultDto>(qry);
            this.handleAuthenticationResult(data);
         } catch (e) {
            this.toastr.error('Authentication operation completed with errors. Please contact system administrator');
            this.logger.error(e);
            this.isLoading = false;
            this.updateLoginButtonText();
         }
      } else {
         this.loginToShell(this.selectedShell);
      }
   }

   onLogoutButtonClicked(): void {
      this.logger.info('Requested logout');
      this.keepSessionAliveService.stop();
      const sessionData = this.sessionService.sessionData;
      if (!sessionData) {
         this.logger.info('Session data does not exist. Logout will not proceed');
         this.isLoading = false;
         return;
      }
      const authToken = sessionData.authToken;
      if (!authToken) {
         this.logger.info('Auth token does not exist. Logout will not proceed');
         this.isLoading = false;
         return;
      }
      const cmd = new DismissToken(authToken, 'user request');
      this.isLoading = true;
      this.authClient
         .processCommand(cmd)
         .then(() => {
            this.logger.info('User successfully logged out', { authToken });
            this.isLoading = false;
            this.isAuthenticated = false;
            this.availableShells = [];
            this.selectedShell = null;
            this.credentials = {};
            this.updateLoginButtonText();
            this.sessionService.reset();
         })
         .catch(err => {
            this.logger.error('Logout request failed', { error: err });
            this.toastr.error('Error occurred during logout', 'Problem');
            this.isLoading = false;
         });
   }

   private handleAuthenticationResult(dto: AuthResultDto): void {
      this.logger.info('Authentication service responded', dto);

      if (!dto.error) {
         this.logger.info('User was successfully authenticated', { token: dto.authToken });
         this.isAuthenticated = true;
         this.sessionService.sessionData = dto;
         if (dto.availableShells && dto.availableShells.length) {
            this.keepSessionAliveService.start();
            if (dto.availableShells.length === 1) {
               this.loginToShell(dto.availableShells[0]);
            } else {
               this.isLoading = false;
               this.updateLoginButtonText();
               this.availableShells = dto.availableShells;
            }
         } else {
            this.logger.info('User has no shells assigned', { token: dto.authToken });
            this.toastr.info('You have no shells assigned to you', 'Warning');
            this.onLogoutButtonClicked();
         }

      } else {
         this.logger.info('User authentication failed', { error: dto.error });
         this.toastr.error(dto.error, 'Problem');
         this.onLogoutButtonClicked();
      }

      this.updateLoginButtonText();
   }

   private loginToShell(shell: AvailableShellDto): void {
      this.logger.info('User requested shell login', { shell });

      if (!shell) {
         this.logger.info('Cannot login to shell: not specified');
         this.toastr.error('Please select shell to login to', 'Problem');
         return;
      }

      this.isLoading = true;
      this.sessionService.connectedShell = shell;
      const query = new TerminalLogin();
      this.shellClient
         .processQuery<TerminalLoginResultDto>(query)
         .then(data => {
            this.logger.info('User successfully logged in to shell', { shell });
            this.isLoading = false;
            this.sessionService.loginResult = data;
            return this.router.navigate(['/init'], { skipLocationChange: true });
         })
         .catch((err: HttpErrorResponse) => {
            this.sessionService.connectedShell = null;

            if (!(this.availableShells && this.availableShells.length > 1)) {
               this.onLogoutButtonClicked();
            }

            this.isLoading = false;

            if (err.status === 401) {
               this.logger.error('Access Denied', err);
               this.toastr.error('Access Denied', 'Request Unauthorized');
            } else {
               this.logger.error('Terminal login failed', err);
               this.toastr.error(
                  'Failed to login to shell. That could have happened beacuse of communication errors or shell not being online',
                  'Problem'
               );
            }
         });
   }

   private updateLoginButtonText(): void {
      if (this.isLoading) {
         this.loginButtonText = 'Authenticating.....';
      } else {
         this.loginButtonText = this.isAuthenticated ? 'Login to Shell' : 'Sign In';
      }
   }
}
