<div class="ets-host">
  
  <div [ngClass]="{'empty': !this.isEtsHost}" class="ets-host-header">
    
    <div class="part side left">
      
      <div *ngIf="this.isEtsHost" class="btn logo">
        <img src="assets/logo.png" style="margin-left: 3px">
      </div>
      
      <div *ngIf="this.securityContext.tradeButton" class="btn trading-pad">
        <dx-button
          (onClick)="this.toggleTradingPad()"
          class="ets-header-button trade"
          height="100%"
          text="Trade"
          width="100%"
        >
        </dx-button>
      </div>
      
      <div class="btn menu">
        <ets-host-menu *ngIf="securityContext.menuButton"
                       [uniqueId]="'default-host-menu'"
                       class="ets-header-button"
        >
        </ets-host-menu>
      </div>
      
    </div>
    
    <div class="part side right">
      
      <div class="btn terminal-exit">
        <ets-terminal-exit
          (terminalExitRequest)="onTerminalExitRequestPressed($event)"
          *ngIf=securityContext.terminalExit
          class="ets-header-button terminal-exit"
        >
        </ets-terminal-exit>
      </div>
      
      <div class="btn master-exit">
        <dx-button
          (onClick)="onMasterExitPressed()"
          [visible]="securityContext.masterExit"
          class="ets-header-button master-exit"
          height="100%"
          text="Master Exit"
          width="100%"
        >
        </dx-button>
      </div>
    </div>
  
  
  </div>
  
  <div [ngClass]="{'extended': !this.isEtsHost}" class="ets-host-content">
    
    <div *ngIf="isTradingPadVisible && securityContext.tradeButton" class="trading-pad-host">
      <ets-trading-pad></ets-trading-pad>
    </div>
    
    <div *ngIf="isEventStreamConnectionLost" id="ess-badge">{{ essConnectionLostMessage }}</div>
    
    <div *ngIf="isShellOffline" id="shell-badge">{{ shellOfflineMessage }}</div>
    
    <ets-layout-tabs
      (onAddTab)="onAddTabRequest()"
      (onCloseTab)="onCloseTabRequest($event)"
      (onHeaderChanged)="onLayoutChanged()"
      (sortLayouts)="onSortLayoutsRequest()"
      (tabSelected)="onLayoutSelected($event)"
      [tabs]="layouts"
      id="layout-tabs"
    >
    </ets-layout-tabs>
    
    <div [ngStyle]="layoutSectionStyle">
      <ng-container *ngFor="let layout of layouts">
        <ets-layout (layoutChanged)="onLayoutChanged()" [ngClass]="{'hidden': !layout.isActive}"
                    [rootSection]="layout"></ets-layout>
      </ng-container>
    </div>
  
  </div>
  
  <div class="ets-host-footer">
    
    <div class="footer-wrapper">
      
      <div class="section left">
        
        <div *ngIf="securityContext.sessionPnL" class="element session-pnl">
          <ets-pnl-box [pnl]="this.terminalSessionPnL" prefix="S"></ets-pnl-box>
        </div>
        
        <div *ngIf="securityContext.accumulatedPnL" class="element accumulated-pnl">
          <ets-pnl-box [pnl]="this.terminalAccumulatedPnL" prefix="A"></ets-pnl-box>
        </div>
        
        <div *ngIf="securityContext.gatewaysManager" class="element gateways">
          <ets-gateway-manager></ets-gateway-manager>
        </div>
        
        <div *ngIf="securityContext.sessionMessages" class="element shell-messages">
          <ets-shell-messages-overlay></ets-shell-messages-overlay>
        </div>
        
        <div *ngIf="securityContext.jobsTracker" class="element jobs-tracker">
          <ets-jobs-tracker></ets-jobs-tracker>
        </div>
        
        <div *ngIf="securityContext.totalStrategiesIssues" class="element strategy-issues">
          <ets-total-strategy-issues></ets-total-strategy-issues>
        </div>
        
        <div *ngIf="securityContext.unaccountedData" class="element unaccounted-data">
          <ets-unaccounted-data-indicator></ets-unaccounted-data-indicator>
        </div>
        
      </div>
      
      <div class="section center">
        <div *ngIf="this.securityContext.currentMarketPrices" class="element prices">
          <div class="market-prices">
         
            <div class="price-box">
              <div class="ticker">ES</div>
              <div class="data">
                <ets-last-price
                  [showChange]="true"
                  [showLabel]="false"
                  [asset]="'ESH5'"
                ></ets-last-price>
              </div>
            </div>
            
            <div class="price-box">
              <div class="ticker">SPX</div>
              <div class="data">
                <ets-last-price
                  [showChange]="true"
                  [showLabel]="false"
                  [asset]="'SPX'"
                ></ets-last-price>
              </div>
            </div>
            
            <div class="price-box">
              <div class="ticker">SPY</div>
              <div class="data">
                <ets-last-price
                  [showChange]="true"
                  [showLabel]="false"
                  [asset]="'SPY'"
                ></ets-last-price>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      
      <div class="section right">
        
        <div class="quote-counter" *ngIf="this.isSuperUser">
          <div>{{ this.quoteCounter || '*' }}</div>
          <div>Quotes Used</div>
        </div>
        
        <div class="element time-panel">
          <ets-server-time-panel *ngIf="securityContext.serverTimePanel">
          </ets-server-time-panel>
        </div>
      
      </div>
      
    </div>
  
  </div>
  
  <dx-popup [closeOnOutsideClick]="false" [dragEnabled]="false" [height]="250" [resizeEnabled]="false"
            [showTitle]="false"
            [visible]="groupExitDetails.isConfirmationVisible" [width]="300">
    <div *dxTemplate="let data of 'content'" id="group-exit_confirmation-dialog">
      <div id="group-exit_tree">
        <p>
          {{ groupExitDetails.message }}
        </p>
      </div>
      <div id="group-exit_buttons">
        <dx-button (onClick)="confirmGroupExit('yes')" text="Yes" type="normal" width="100"></dx-button>
        <dx-button (onClick)="confirmGroupExit('no')" text="Cancel" type="normal" width="100"></dx-button>
      </div>
    </div>
  </dx-popup>
  
  <ets-layouts-sort (sortLayouts)="onSortLayoutsCompleted($event)" [isVisible]="isLayoutsSortDialogVisible"
                    [layouts]="layouts"></ets-layouts-sort>

</div>

<ets-normalizing-calculator></ets-normalizing-calculator>


<audio id="myAudio" style="visibility: hidden;">
  <source src="assets/beep-07a.mp3" type="audio/mpeg">
</audio>

